body {
  width: 100%;
  height: 100%;
  color: #777;
  background: #000;
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}

h1 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.menu-toggle {
  font-size: 150%;
  font-weight: bold;
}

.map {
  width: 100%;
  z-index: -1000;
  background: none;
  position: fixed;
}

.layer-radio {
  display: block;
}

.controls {
  width: 333px;
  text-align: center;
  margin: 0 auto;
  padding: 2px;
  font-size: 16px;
  font-weight: bold;
  display: none;
}

.top-ctrl-background {
  z-index: -1;
  width: 333px;
  height: 77px;
  background: #000c;
  position: absolute;
  transform: perspective(10px)rotateX(-1deg);
}

.controls label h1 {
  cursor: pointer;
}

#layers-wrapper h1 {
  width: 390px;
  border-bottom: 2px solid #b4d678;
  margin-top: 50px;
  margin-bottom: 24px;
  padding-bottom: 14px;
  padding-left: 10px;
  font-size: 20px;
  line-height: 20px;
}

#layers-wrapper h1 img {
  margin-bottom: -7px;
  margin-right: 10px;
}

.info-wrapper {
  width: 391px;
  max-height: 388px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-align: right;
  background: #000c;
  position: absolute;
  bottom: 16px;
  left: 188px;
  overflow-y: scroll;
}

#feature-info {
  margin: 32px;
  display: none;
}

#layers-wrapper {
  margin: 48px;
}

#layers-wrapper label {
  cursor: pointer;
  margin: 4px 4px 4px 8px;
  font-size: 16px;
  display: block;
}

input[type="radio"], input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

#layers-wrapper label:hover {
  color: #fff;
}

.layer-group {
  overflow: hidden;
}

input[type="radio"]:checked + label {
  color: #fff !important;
}

.lg-toggle-label {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  right: 0;
  margin: -24px 60px 0 0 !important;
}

.lg-toggle-label:hover {
  background: #333;
}

#home-ref {
  position: absolute;
  top: 28px;
  right: 32px;
}

#menu-toggle-icon {
  margin-top: 70px !important;
  margin-bottom: 90px !important;
}

.slide-container {
  width: 100%;
}

.slider {
  appearance: none;
  width: 100%;
  height: 2px;
  opacity: 1;
  background: none;
  border-radius: 5px;
  outline: none;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  width: 28px;
  height: 17px;
  z-index: 99;
  background-image: url("opacity-visible.9427910c.svg");
  position: relative;
  top: 0;
  opacity: 1 !important;
}

.slider::-moz-range-thumb {
  cursor: pointer;
  background-image: url("opacity-visible.9427910c.svg");
}

.icon {
  width: auto;
  height: 24px;
  margin-bottom: -6px;
}

#layers-menu-icon {
  opacity: .5;
}

.h-arrow-left {
  margin-right: 50px;
  font-size: 150%;
  display: none;
}

.h-arrow-right {
  margin-left: 50px;
  font-size: 150%;
  display: none;
}

.legend-wrapper {
  max-height: 100%;
  min-width: 172px;
  opacity: .8;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #000;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.legend-wrapper::-webkit-scrollbar {
  display: none;
}

.right-menu::-webkit-scrollbar {
  display: none;
}

.info-wrapper::-webkit-scrollbar {
  display: none;
}

.ol-attribution {
  color: #000;
  opacity: .75;
  border-radius: 0;
  background: #000 !important;
}

.ol-attribution ul {
  color: #bbb;
  text-shadow: none;
}

.ol-attribution a {
  color: inherit;
}

.ol-control button {
  color: #000;
  border-radius: 0;
  background: #bbb !important;
}

.ol-control button:hover {
  filter: invert(90%);
}

.right-menu {
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #000c;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  body {
    text-align: center;
    font-size: 120%;
  }

  .controls {
    width: 100%;
    padding-left: 0;
    left: 0;
  }

  .info-wrapper {
    width: 100%;
    text-align: left;
    padding: 0;
    inset: auto 0 0;
  }

  .h-arrow-left, .h-arrow-right {
    display: inline;
  }

  .legend-wrapper {
    max-width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    bottom: 67px;
    left: auto;
    right: 0;
    overflow-x: scroll;
  }

  .ol-attribution {
    z-index: 2000;
    margin-bottom: 12px;
  }
}

/*# sourceMappingURL=index.afc40ec6.css.map */
