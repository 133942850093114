
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #777;
    background: #000;
}
h1 {
    font-size: 16px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    padding: 0;
    margin: 0;
    color: #fff;
}
.menu-toggle {
    font-weight: bold;
    font-size: 150%;
}
.map {
    width: 100%;
    /* height: 200%; */
    position: fixed;
    background: transparent;
    z-index: -1000;
}
.layer-radio {
    display: block;
}
.controls {
    /* position: absolute; */
    /* top: 5px; */
    /* right: 5px; */
    margin: 0 auto;
    /* width: 333px; */
    width: 333px;
    /* background: rgba(0, 0, 0, 0.8); */
    padding: 2px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: none;
}
.top-ctrl-background {
    position: absolute;
    z-index: -1;
    /* top: 0; */
    /* left: 0; */
    width: 333px;
    height: 77px;
    background: rgba(0, 0, 0, 0.8);
    transform: perspective(10px) rotateX(-1deg);
    /* margin: 50px; */
}
.controls label h1 {
    cursor: pointer;
    /* text-align: right; */
}
#layers-wrapper h1 {
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 14px;
    padding-left: 10px;
    margin-top: 50px;
    margin-bottom: 24px;
    width: 390px;
    border-bottom: 2px solid #B4D678;
}
#layers-wrapper h1 img {
    margin-right: 10px;
    margin-bottom: -7px;
}
.info-wrapper {
    position: fixed;
    position: absolute;
    width: 391px;
    max-height: 388px;
    left: 188px;
    bottom: 16px;
    background: rgba(0, 0, 0, 0.8);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    text-align: right;
}
#feature-info {
    /* background: #000; */
    /* padding: 5px; */
    margin: 32px;
    display: none;
}
#layers-wrapper {
    /* display: grid; */
    /* grid-template-columns: 200px 200px 200px 200px 200px; */
    /* overflow-y: scroll; */
    margin: 48px;
}
#layers-wrapper label {
    display: block;
    cursor: pointer;
    margin: 4px;
    margin-left: 8px;
    font-size: 16px;
    /* background: #000; */
}
input[type='radio'], input[type='checkbox'] {
    position: absolute;
    opacity: 0;
}
#layers-wrapper label:hover {
    color: #fff;
}
.layer-group {
    overflow: hidden;
}
input[type='radio']:checked + label {
    color: #fff !important;
}
.lg-toggle-label {
    display: block;
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    margin: 0 !important;
    margin-right: 60px !important;
    margin-top: -24px !important;
}
.lg-toggle-label:hover{
    background: #333;
}
#home-ref {
    position: absolute;
    top: 28px;
    right: 32px;
}
#menu-toggle-icon {
    margin-top: 70px !important;
    margin-bottom: 90px !important;
}
.slide-container {
  width: 100%; /* Width of the outside container */
}
/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 2px; /* Specified height */
    background: transparent; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    border-radius: 5px;
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-image: url('./assets/opacity-visible.svg');
    opacity: 1 !important;
    width: 28px;
    height: 17px;
    position: relative;
    top: 0px;
    z-index: 99;
    /* width: 15px; */
    /* height: 15px; */
    /* background: #bbb; */
    /* border-radius: 50%; */
}
.slider::-moz-range-thumb {
    background-image: url('./assets/opacity-visible.svg');
    cursor: pointer;
    /* width: 15px; */
    /* height: 15px; */
    /* background: #bbb; */
    /* border-radius: 50%; */
}
.icon {
    width: auto;
    height: 24px;
    margin-bottom: -6px;
}
#layers-menu-icon {
    opacity: .5;
}
.h-arrow-left {
    margin-right: 50px;
    font-size: 150%;
    display: none;
}
.h-arrow-right {
    margin-left: 50px;
    font-size: 150%;
    display: none;
}
.legend-wrapper {
    position: fixed;
    left: 0;
    margin: 0;
    top: 0;
    max-height: 100%;
    overflow-y: scroll;
    background: #000;
    min-width: 172;
    opacity: .8;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.legend-wrapper::-webkit-scrollbar {
    display: none;
}
.right-menu::-webkit-scrollbar {
    display: none;
}
.info-wrapper::-webkit-scrollbar {
    display: none;
}

/* openlayers attribution styling */
.ol-attribution {
    color: black;
    background: black !important;
    border-radius: 0;
    opacity: .75;
}
.ol-attribution ul {
    color: #bbb;
    text-shadow: none;
}
.ol-attribution a {
    color: inherit;
}
.ol-control button {
    background: #bbb !important;
    color: black;
    border-radius: 0;
}
.ol-control button:hover {
    filter: invert(90%);
}

.right-menu {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 600px) {

body {
    font-size: 120%;
    text-align: center;
}
.controls {
    width: 100%;
    left: 0;
    padding-left: 0;
}
.info-wrapper {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    padding: 0;
    text-align: left;
}
#layers-wrapper {
    /* max-width: 100%; */
    /* height: 400px; */
    /* overflow: scroll; */
    /* grid-template-columns: 100% 100% 100% 100% 100%; */
}
.h-arrow-left, .h-arrow-right {
    display: inline;
}
.legend-wrapper {
    max-width: 100%;
    overflow-x: scroll;
    bottom: 67px;
    left: auto;
    right: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ol-attribution {
    margin-bottom: 12px;
    z-index: 2000;
}
}
